import { cleanUpSessionStorage, persistInSessionStorage, pushToDataLayer } from './utils'

const trackingKeys = ['fbclid', 'gclid', 'obid', 'tbid', 'promo_code']

export const persistTracking = (): void => {
  persistInSessionStorage(key => key.startsWith('utm_') || trackingKeys.includes(key))
}

export const cleanUpTracking = (): void => {
  cleanUpSessionStorage(key => key.startsWith('utm_') || trackingKeys.includes(key))
}

const generateIfId = (ifIdCounter: number): string => {
  const baseId = '00000000-0000-0000-0000-'
  const newId = ifIdCounter.toString(16).padStart(12, '0')
  return `${baseId}${newId}`
}

export const createHiddenFields = (form: HTMLFormElement, params: Record<string, string>): void => {
  let ifIdCounter = 1 // Counter for if-id

  Object.entries(params).forEach(([key, value]) => {
    if (!form.querySelector(`input[name="${key}"]`)) {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.id = key
      hiddenField.name = key
      hiddenField.setAttribute('data-name', key)
      hiddenField.setAttribute('if-id', generateIfId(ifIdCounter))
      hiddenField.value = value
      form.appendChild(hiddenField)
      ifIdCounter += 1
    }
  })
}

export const populateFormFields = (forms: HTMLFormElement[]): void => {
  forms.forEach((form) => {
    const trackingParams: Record<string, string> = Object.entries(sessionStorage)
      .filter(([key]) => key.startsWith('utm_') || trackingKeys.includes(key))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

    trackingParams['flowId'] = window.location.pathname
    trackingParams['createdAt'] = new Date().toISOString()
    createHiddenFields(form, trackingParams)
  })
}

export const populateDataLayer = (): void => {
  const trackingParams: Record<string, string> = Object.entries(sessionStorage)
    .filter(([key]) => key.startsWith('utm_') || trackingKeys.includes(key))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

  pushToDataLayer(trackingParams)
}
